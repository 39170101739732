<template>
  <div class="message-card">
    <div class="header" v-if="name">
      <div class="name">{{name}}</div>
      <!-- <div class="total">发送条数:{{total}}</div> -->
    </div>
    <div class="content">{{content}}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.message-card {
  width: 351px;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  border-radius: 10px;
  padding: 12px;
  box-sizing: border-box;
  margin: 12px auto 0;
  text-align: left;
  .header {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #91939A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .content {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    color: #2C2C2C;
  }
}
</style>